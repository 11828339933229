<template>
    <div class="list__item item_view_unit item_view_unit__digital_matter"
         :class="{
            'item_active': isActive,
            'item_view_unit__group': subUnits.length
         }"
         :data-group-id="groupId" :data-unit-id="unitId"
         @click.stop="setUnitActive"
         @mouseover="showMenu = true" @mouseleave="showMenu = false"
    >
        <UnitsList_unit_icons :unitId="unitId" :indicators="indicators" :default-icon="'bluetooth-icon'" class="list__symbol"/>

        <div class="list__title-line">
            <h4 class="list__title">
                <span>{{unitName}}</span>
                (<UnitsList_unit_count :unit-id="unitId" />)
            </h4>
            <UnitsList_unit_timeago :unit-id="unitId" style="font-size: 10px"/><!-- ! -->
            <UnitsList_unit_date :unit-id="unitId" v-if="false"/>
        </div>
        <div class="list__address-line">
            <UnitsList_unit_address :unit-id="unitId" /><!-- ! -->
            <UnitsList_unit__status :unit-id="unitId" v-if="false"/><!-- ! -->
        </div>
        <div class="item_view_unit__dropdown" v-if="subUnits.length">
            <button class="button button_command" @click="showSubUnits = !showSubUnits">
                <SvgIcon :name="showSubUnitsButtonIcon" class="icon"/>
            </button>
        </div>
    </div>
    <div class="item_view_unit__group--active" v-show="showSubUnits" v-if="subUnits.length">
        <template v-for="unit in subUnits" :key="unit.unitId">
            <component :is="'UnitsList_unit'+unit.type" v-bind="unit"/>
        </template>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    import UnitsList_unit_count from "@/components/units/UnitsList_unit_digital_matter_count.vue";
    import UnitsList_unit_timeago from "@/components/units/UnitsList_unit_timeago";
    //import UnitsList_unit_date from "@/components/units/UnitsList_unit_digital_matter_date.vue";
    import UnitsList_unit_address from "@/components/units/UnitsList_unit_address.vue";
    import UnitsList_unit__status from "@/components/units/UnitsList_unit__status.vue";
    //import UnitsList_unit_menu from "@/components/units/UnitsList_unit_menu.vue";
    import UnitsList_unit_menu from "@/components/units/UnitsList_unit_menu.intuit.vue";
    import UnitsList_unit_icons from "@/components/units/UnitsList_unit_icons.vue";
    import {computed} from "vue";

    import UnitsList_unit from "@/components/units/UnitsList_unit.intuit.vue"
    import UnitsList_unit_xpower from "@/components/units/UnitsList_unit_xpower.intuit.vue"
    import UnitsList_unit_xlink from "@/components/units/UnitsList_unit_xlink.intuit.vue"
    import UnitsList_unit_xtag from "@/components/units/UnitsList_unit_xtag.intuit.vue"
    // let indicatorsByStatus = {
    //     default: ['ignition', 'moving', 'text'],
    //     video: ['ignition', 'moving', 'text', 'video'],
    //     reefer: ['ignition', 'moving', 'text', 'reefer'],
    // }

    export default {
        name: 'UnitsList_unit_digital_matter',
        inject: ['setRoute'],
        components: {
            UnitsList_unit_icons,
            UnitsList_unit_address,
            UnitsList_unit_count,
            UnitsList_unit_timeago,
            //UnitsList_unit_date,
            UnitsList_unit__status,
            UnitsList_unit_menu,
            UnitsList_unit,
            UnitsList_unit_xpower,
            UnitsList_unit_xlink,
            UnitsList_unit_xtag,
        },
        props: {
            'unitId': [String, Number],
            'groupId': [String, Number],
        },
        data() {
            return {
                modalsWindows: {},
                showMenu: true,
                showSubUnits:false,
            }
        },
        provide() {
            return {
                modalsWindows: computed(() => this.modalsWindows)
            }
        },
        computed: {
            ...mapGetters([
                "getTimeToday",
                "unitsByIds",
                // "groupsShowUnits",
                // "groupsShowUnitsByIds",

                "units",
            ]),
            routeName() {
                return this.$route.name
            },
            routeFilter() {
                return this.$route.params
            },
            routeQueryFilter() {
                return this.$route.query
            },
            unit(){
                return this.unitsByIds[this.unitId]
            },
            unitName(){
                return this.unit?.name || ''
            },
            indicators() {
                // let status = 'default'
                // if(this.isReefer) status = 'reefer'
                // else if(this.hasVideo) status = 'video'
                return []//indicatorsByStatus[status]
            },
            isActive(){
                return this.routeFilter.unitId == this.unitId
            },
            showSubUnitsButtonIcon() {
                return `vector-` + (this.showSubUnits ? 'top' : 'down')
            },
            subUnits() {
                return this.units
                    .filter(u => u.parent__id === this.unitId)
                    .map(unit => {
                        let type = unit?.hw_type
                        type = this.$consts.unitsList.useTypeForTemplate.includes(type) ? '_'+type : ''
                        return {xid: this.groupId + unit.id, groupId: this.groupId, type, unitId: unit.id, parentId: this.unitId}
                    })
            }
        },
        watch: {
            isActive(newVl) {
                if (!newVl) {
                    this.showSubUnits = false
                }
            },
        },
        methods: {
            ...mapMutations([
            ]),
            ...mapActions([
            ]),
            setUnitActive(){
                this.setRoute.unitId(this.$route, this.$router, this.unitId)
            },
        },
        updated() {
            // console.log(`unit ${this.unitId} updated`);
        },
        mounted() {
        },
        beforeUnmount() {
        },
    }
</script>
<style lang="scss" scoped>
.prometheus-portal {
    .list {
        &__item {
            &.item_view_unit {
                &__digital_matter {
                    grid-template-columns: 50px calc(100% - 75px);

                    .list__symbol {
                        align-items: baseline;
                        grid-row: 1 / 3;
                        grid-row-end: 3;
                    }

                    .list__address-line {
                        grid-column: 2 / 3;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss">
.prometheus-portal {
    .list {
        &__item {
            &.item_view_unit {
                &__digital_matter {
                    .symbol__image-box {
                        width: 33px;
                        height: 38px;
                    }
                }
            }
        }
    }
}
</style>