const RESOURCE_NAME = '/units-helper';

export default axios => ({
	getTypes(params) {
		return axios.get(`${RESOURCE_NAME}/types`, {params});
	},
	getHwTypes(params) {
		return axios.get(`${RESOURCE_NAME}/hw-types`, {params});
	},
	getIcons(params) {
		return axios.get(`${RESOURCE_NAME}/icons`, {params});
	},
	getHwTypesSettings(params) {
		return axios.get(`${RESOURCE_NAME}/hw-types-settings`, {params});
	}
});