// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";

const defaultSetRoute = {
    units: (route, router) => {
        if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
            if(this.$isDevelopment) console.warn('units', route);
            return false
        }
        router.push({
            name: consts.routerNames.units.main,
            params: {},
            query: {}
        })
    },
    unitsEdit: (route, router, unitId) => {
        if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
            if(this.$isDevelopment) console.warn('unitsEdit', route);
            return false
        }
        router.push({
            name: consts.routerNames.units.edit.main,
            params: {
                unitId,
            },
            query: {...route.query}
        })
    },
    unitsCreate: (route, router) => {
        if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
            if(this.$isDevelopment) console.warn('unitsCreate', route);
            return false
        }
        router.push({
            name: consts.routerNames.units.edit.create,
            params: {
            },
            query: {...route.query}
        })
    },
    unitsHistory: (route, router, unitId) => {
        if(route.meta?.mainRouteName !== consts.routerNames.units.main) {
            if(this.$isDevelopment) console.warn('unitsHistory', route);
            return false
        }
        router.push({
            name: consts.routerNames.units.history,
            params: {
                unitId
            },
            query: {...route.query}
        })
    },
}

const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.admin') ? [] : [
    {
        path: 'units',
        name: consts.routerNames.units.main,
        meta: {
            mainRouteName: consts.routerNames.units.main,
            bodyGrid: "1X1",
            showHead: true,
            setRoute: defaultSetRoute,
        },
        component: () => import('@/views/intuit/admin/UnitsPage.vue'),
        children: [
            {
                path: ':unitId(\\d+)/edit',
                name: consts.routerNames.units.edit.main,
                meta: {
                    sectionWidgetByRoute: true,
                },
                props: {
                    sectionWidgetByRoute: (route) => ({
                        unitId: route.params.unitId,
                        time: Date.now(),
                    })
                },
                components: {
                    sectionWidgetByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                },
            },
            {
                path: 'create',
                name: consts.routerNames.units.edit.create,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            // class: 'popup-full',
                            titlePosition: 'center'
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: () => ({
                        wrapper: 'EditWizard',
                        class: 'popup-body',
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/units/sectionInfo/UnitEdit.vue'),
                }
            },
            {
                path: ':unitId(\\d+)/history',
                name: consts.routerNames.units.history,
                meta: {
                    sectionPopupByRoute: {
                        SectionWrapByRoutePopup: {
                            class: 'popup-full',
                            titlePosition: 'left'
                        }
                    }
                },
                props: {
                    sectionPopupByRoute: (route) => ({
                        objectName: 'unit',
                        objectId: route.params.unitId,
                    })
                },
                components: {
                    sectionPopupByRoute: () => import('@/components/objects_audit/objects/ObjectsChangesTableTreeList.vue'),
                }
            }
        ]
    },
];

export default routes;