const RESOURCE_NAME = '/insurance-reports';
import restApi from "@/api/rest-api-object";

export default axios => ({
    ...restApi(axios, RESOURCE_NAME),
    removed(id, params) {
        return axios.post(`${RESOURCE_NAME}/${id}/removed`, {}, {params});
    },
    restore(id, params) {
        return axios.post(`${RESOURCE_NAME}/${id}/restore`, {}, {params});
    },

    push2Driver(id, driverId, params) {
        return axios.post(`${RESOURCE_NAME}/${id}/push-to-driver`, {driverId}, {params});
    },
});