<template>
    <div class="checkbox-control"
         :class="[controlClass, fieldGroup__class]"
    >
        <div>
            <label class="checkbox-control__label">
                <span class="checkbox-control__text">{{ label }}</span>
                <input class="checkbox-control__checkbox"
                       :type="type"
                       :name="name"
                       :value="customValue"
                       :disabled="disabled"
                       v-model="checkbox"

                >
                <span class="checkbox-control__switch"></span>
            </label>
        </div>

        <ul class="checkbox-control-errors" v-if="warnings?.length || errors?.length">
            <template v-for="(warning, i) in warnings" :key="i">
                <li class="checkbox-control-errors__item-warning">
                    <SvgIcon class="field-group__help__error-list-warning__icon" name="common__warning"/>
                    {{ warning }}
                </li>
            </template>
            <template v-for="(error, i) in errors" :key="i">
                <li class="checkbox-control-errors__item-error">{{ error }}</li>
            </template>
        </ul>
    </div>
</template>

<script>
export default {
    name: "CheckboxControl",
    emits:['update:modelValue'],
    props: {
        'fieldGroup_class' : {
            type: [String, Array, Object],
            default: () => ''
        },
        'controlClass': {
            type: [String, Array, Object],
            default: () => ''
        },
        'label': String,
        'modelValue': [String, Number, Object, Array, Boolean],
        'customValue': [String, Number, Object, Array],
        'disabled' : [Boolean],
        'isRadio': {
            type: Boolean,
            default: () => false
        },
        'error': {
            type: [String, Array, Object],
            default: () => ''
        },
        'warning': {
            type: [String, Array, Object],
            default: () => ''
        },
        name: {
            type: String,
            default: () => ''
        },
        type: {
            type: String,
            default: () => 'checkbox'
        }
    },
    data() {
        return {}
    },
    computed: {
        checkbox: {
            get(){ return this.modelValue },
            set(v){
                if(this.isRadio && !v) return false;
                this.$emit('update:modelValue', v)
            }
        },
        fieldGroup__class() {
            let fieldGroup_class = this.fieldGroup_class
            let type = (typeof this.fieldGroup_class)
            if (type === 'string') {
                return fieldGroup_class
                    + ((this.error > '') ? ' has-error ' : '')
                    + ((this.warning > '') ? ' has-warning ' : '')
                    + ((this.disabled === true) ? ' is_disabled ' : '')
            } else
            if (type === 'object') {
                if (Array.isArray(this.fieldGroup_class)) {
                    if (this.error > '') fieldGroup_class.push('has-error')
                    if (this.warning) fieldGroup_class.push('has-warning')
                    if (this.disabled === true) fieldGroup_class.push('is_disabled')
                    return fieldGroup_class
                } else {
                    return {
                        'is_disabled': (this.disabled === true),
                        'has-error': (this.error > ''),
                        'has-warning': (this.warning > ''),
                        ...fieldGroup_class
                    }
                }
            }
            console.error('FieldGroup_input::fieldGroup__class', type, fieldGroup_class)
            return this.fieldGroup_class
        },
        errors() {
            if (!this.error) return []
            let type = (typeof this.error)
            if (type === 'string') {
                return [this.error]
            } else
            if (type === 'object' && Array.isArray(this.error)) {
                return this.error.reduce( (errors , e) => {
                    if(e.$message > ''){//vuelidate
                        errors.push(e.$message)
                    } else
                    if(e.$response){//vuelidate api
                        if(e.$response.$messages && e.$response.$messages.length)
                            errors.push(...e.$response.$messages)
                    } else
                    if (typeof e === 'string') {// string
                        errors.push(e)
                    } else {
                        console.warn('errors:', e)
                    }
                    return errors
                },[])
            }
            console.error('FieldGroup_input::errors', type, this.error)
            return []
        },
        warnings() {
            if (!this.warning) return []
            let type = (typeof this.warning)
            if (type === 'string') {
                return [this.warning]
            } else
            if (type === 'object' && Array.isArray(this.warning)) {
                return this.warning
            } else {
                let keys = Object.keys(this.warning)
                return  keys.map(k => this.warning[k])
            }
        },
    },
    methods:{
        // setValue(){
        //     if(!this.disabled) this.checkbox = !this.checkbox
        // }
    }
}
</script>

<style lang="scss" scoped>
.checkbox-control {
    display: flex;
    //align-items: center;
    flex-direction: column;
    //height: 2.25rem;
    min-height: 2.25rem;
    //background: var(--color-white);
    border-radius: var(--border-radius-lg);
    transition: all var(--transition-duration-xl) ease-in-out;

    &:hover {
        //background: rgba(var(--color-white-rgb), .75);
    }

    &__checkbox {
        opacity: 0;
        visibility: hidden;
    }

    &__label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        padding: .5rem 1rem;
        cursor: pointer;
        position: relative;
        user-select: none;
        @include font(14px, 14px, 700, none, normal, var(--color-font-light));

        & > input[type="checkbox"],
        & > input[type="radio"] {
            //display:none;
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            display: flex;
            font-size: 0;
            top: 0;
            left: 0;
            border-radius: var(--border-radius-lg);
            z-index: -1;
            appearance: none;
            outline: none;

            &:focus {
                box-shadow: 0 0 0 2px var(--color-primary-500);
            }

            &[disabled] + .checkbox-control__switch {
                cursor: not-allowed;
                background-color: var(--color-neutral-50);
            }

            &:checked + .checkbox-control__switch {
                background: var(--color-secondary-600);

                &:before {
                    left:50%;
                }
            }

            &:checked[disabled] + .checkbox-control__switch {
                background: var(--color-secondary-200);
            }
        }
    }

    &__switch {
        height: 1.25rem;
        left: 0;
        background-color: var(--color-neutral-100);
        border-radius: .625rem;
        display: inline-block;
        position: relative;
        top: 0;
        transition: all var(--transition-duration-xl) ease-in-out;
        width: 2.25rem;
        min-width: 2.25rem;
        cursor: pointer;

        &:before {
            display: block;
            content:'';
            height: 1rem;
            position: absolute;
            width: 1rem;
            background-color: var(--checbox-color);
            border-radius: 50%;
            left: 0.125rem;
            top: 0.125rem;
            transition: all var(--transition-duration-xl) ease-in-out;
            //box-shadow: 0 1px 0 0 rgba(0, 21, 51, 0.2);
            box-shadow: 0 1px 0 0 rgba(var(--color-neutral-700-rgb), .125);
        }
    }

    &__text {
        margin-right: .5rem;
    }

    .checkbox-control-errors {
        padding-left: 20px;
        &__item-warning {

        }
        &__item-error {
            @include font(12px, 20px, 600, none, normal, red);
        }
    }
}
.has-error {
    .checkbox-control {
        &__text {
            color: red;
        }
    }
}
</style>