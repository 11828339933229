<template>
    <section class="section section_type_list is_new-design" :id="list"
             :class="{'section_status_open': $consts.app.$isIntuit ? (!sectionInfoByRoute && showMainList) : showMainList}"
    >

        <SectionUserHead v-if="hasList && $consts.app.$isIntuit" />

        <!--- Section body --->
        <keep-alive :include="keepAliveComponents">
            <component :is="list" />
        </keep-alive>
    </section>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import {mapGetters} from "vuex";
    import SectionUserHead from "@/components/app/SectionUserHead.vue";
    import emptyList from "@/components/app/emptyList.vue";
    import UnitsList from "@/components/units/UnitsList.vue";

    export default {
        setup() {
            return {
                keepAliveComponents: ['UnitsList']
            }
        },
        name: "SectionList",
        props: [],
        data() {
            return {}
        },
        computed: {
            ...mapGetters([
            ]),
            routeMeta() {
                return this.$route.meta
            },
            list() {
                return this.$route.meta.list || 'emptyList'
            },
            showMainList() {
                return !this.$route?.params?.noList
            },
            hasList(){
                return !!this.$route.meta.list
            },
            sectionInfoByRoute() {
                return typeof this.routeMeta?.sectionInfoByRoute === 'function' ?
                    this.routeMeta?.sectionInfoByRoute(this.$route) :
                    this.routeMeta?.sectionInfoByRoute
            }
        },
        components: {
            SectionUserHead,

            emptyList,
            UnitsList,
            GroupsList_md: defineAsyncComponent(() => import("@/components/md/UnitsGroupsList.vue")),

            //'UnitsList':  defineAsyncComponent(() => import('@/components/units/UnitsList.vue')),
            'TracksList':  defineAsyncComponent(() => import('@/components/tracks/TracksList.vue')),
            'ReportsList':  defineAsyncComponent(() => import('@/components/reports/ReportsList.vue')),
            'GeoitemsList':  defineAsyncComponent(() => import('@/components/geoitems/GeoitemsList.intuit.vue')),
            'NotificationsList':  defineAsyncComponent(() => import('@/components/notifications/NotificationsList.vue')),
            'NotificationsEventsList':  defineAsyncComponent(() => import('@/components/notifications_events_history/NotificationsEventsList.vue')),
            'ShipmentList':  defineAsyncComponent(() => import('@/components/shipment/ShipmentList.vue')),

            'RoutesList':  defineAsyncComponent(() => import('@/components/routes/RoutesList.vue')),
            'PersonnelList':  defineAsyncComponent(() => import('@/components/personnel/PersonnelList.vue')),
            'PassengersList':  defineAsyncComponent(() => import('@/components/passengers/PassengersList.vue')),
            'FuelList':  defineAsyncComponent(() => import('@/components/fuel/FuelList.vue')),
            'MaintenanceList':  defineAsyncComponent(() => import('@/components/maintenance/MaintenanceList.vue')),
            'AlarmsList':  defineAsyncComponent(() => import('@/components/alarms/AlarmsList.vue')),
            'VideoList':  defineAsyncComponent(() => import('@/components/video/VideoList.vue')),
            'FuelCardsList':  defineAsyncComponent(() => import('@/components/fuel_cards/FuelCardsList.vue')),

            //prometheus
            // 'UnitsList_prometheus':  defineAsyncComponent(() => import('@/components/units/UnitsList.vue')),
            // 'TracksList_prometheus':  defineAsyncComponent(() => import('@/components/tracks/TracksList.vue')),
            // 'ReportsList_prometheus':  defineAsyncComponent(() => import('@/components/reports/ReportsList.vue')),
            // 'GeoitemsList_prometheus':  defineAsyncComponent(() => import('@/components/geoitems/GeoitemsList.intuit.vue')),
            // 'NotificationsList_prometheus':  defineAsyncComponent(() => import('@/components/notifications/NotificationsList.vue')),
            // 'NotificationsEventsList_prometheus':  defineAsyncComponent(() => import('@/components/notifications_events_history/NotificationsEventsList.vue')),
            //
            // 'RoutesList_prometheus':  defineAsyncComponent(() => import('@/components/routes/RoutesList.vue')),
            // 'PersonnelList_prometheus':  defineAsyncComponent(() => import('@/components/personnel/PersonnelList.vue')),
            // 'PassengersList_prometheus':  defineAsyncComponent(() => import('@/components/passengers/PassengersList.vue')),
            // 'FuelList_prometheus':  defineAsyncComponent(() => import('@/components/fuel/FuelList.vue')),
            // 'MaintenanceList_prometheus':  defineAsyncComponent(() => import('@/components/maintenance/MaintenanceList.vue')),
            // 'AlarmsList_prometheus':  defineAsyncComponent(() => import('@/components/alarms/AlarmsList.vue')),
            // 'VideoList_prometheus':  defineAsyncComponent(() => import('@/components/video/VideoList.vue')),
            // 'FuelCardsList_prometheus':  defineAsyncComponent(() => import('@/components/fuel_cards/FuelCardsList.vue')),

            //admin
            // 'UsersList': defineAsyncComponent(() => import('@/components/users/UsersList.vue')),
            // 'UnitsList': defineAsyncComponent(() => import('@/components/units/UnitsGroupsList.vue')),
            // 'KeysList':  defineAsyncComponent(() => import('@/components/keys/KeysList.vue')),
            // 'ActivationsList': defineAsyncComponent(() => import('@/components/activations/ActivationsList.vue')),
        },
    }
</script>

<style lang="scss">
.section {
    &.section_type_list {
        display: grid;
        //display: none;
        flex-shrink: 0;
        grid-template-rows: auto 1fr auto;
        width: 0;
        height: 100%;
        //height: 100%;
        overflow: hidden;
        background: var(--section-list-bg);
        box-shadow: var(--section-list-box-shadow);
        border: var(--section-list-border);
        //border-right: 0 solid var(--color-white);
        //border-right: 0 solid rgba(var(--color-neutral-75-rgb), 0);
        //border-left: 1px solid var(--color-neutral-75);
        //height: 100%;
        transition: all var(--transition-duration-xxl) cubic-bezier(0.83, 0, 0.17, 1);

        &.section_status_open {
            position: relative;
            width: 25rem;

            & + .section_type_basic{
                .section_type_content {
                    //border-left: 1px solid var(--color-neutral-75);
                }
            }
        }
        .mobile-close-list{
            display: none;
        }

        .section__body {
            display: grid;
            grid-template-rows: auto 1fr;
            overflow: hidden;
            background: var(--section-list-content-background);
            border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
        }

        .section__navigation {
            .navigation__button-group {
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                position: relative;
            }

            .button {
                //background: red;

                &[data-type="open-drop-menu"]:not(:disabled) {
                    &:hover + .navigation__dropdown-menu {
                        display: block;
                    }
                }
            }

            .navigation__dropdown-menu {
                right: calc(100% - 2.5rem);

                &:hover {
                    display: block;
                }

                .dropdown-menu__wrap {
                    &:before {
                        position: absolute;
                        left: 0;
                        top: -.5rem;
                        z-index: 0;
                        width: 100%;
                        height: .5rem;
                        cursor: initial;
                        content: '';
                        background: var(--color-white);
                        opacity: 0;
                    }
                }
            }
        }

        .section__pagination {
            box-shadow: 0 -.25rem .5rem var(--color-neutral-25);
        }
    }
}
.prometheus-portal {
    .section {
        &.section_type_list {
            transition: none;
            .navigation__button-group {
                display: none;
            }
            &.section_status_open {
                width: 360px;
                padding: 10px;
            }
        }
    }

    #UnitsList,
    #GroupsList_md{
        grid-template-rows: 1fr;
    }
}
</style>